import React from "react"
import { Link } from "gatsby"
import "./header.scss"
// import Hamburger from "./hamburger/hamburger"
// import NavMenu from "./nav-menu/nav-menu"

const SiteHeader = props => {
  // const [menuOpen, setIsOpen] = useState(false)
  // const menuToggle = () => setIsOpen(!menuOpen)

  // useEffect(() => {
  //   const main = document.querySelector("main")
  //   const footer = document.querySelector("footer")

  //   return () => {
  //     if (!menuOpen) {
  //       main.classList.add("menu-blur")
  //       footer.classList.add("menu-blur")
  //     } else {
  //       main.classList.remove("menu-blur")
  //       footer.classList.remove("menu-blur")
  //     }
  //   }
  // })

  return (
    <header>
      <div className="container">
        <div className="header-wrapper">
          <Link to="/" className="logo">
            {/* <Link to="/" className={"logo" + (menuOpen ? " menu-blur" : "")}> */}
            Touré
          </Link>
          {/* <Hamburger menuToggle={menuToggle} active={menuOpen} />
          <NavMenu active={menuOpen} menuItems={menuItems} /> */}
        </div>
      </div>
    </header>
  )
}

export default SiteHeader
