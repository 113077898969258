import React from "react"

const Spotify = props => (
  <svg
    width="168px"
    height="168px"
    viewBox="0 0 168 168"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="spotify"
    role="img"
    className={props.className}
  >
    <title id="spotify">Spotify Podcasts Icon for Toure Show</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-70.000000, -299.000000)" fill="#E3D3D0">
        <path d="M203.28,373.24 C176.286,357.209 131.76,355.735 105.991,363.556 C101.852,364.812 97.476,362.476 96.222,358.338 C94.968,354.197 97.302,349.824 101.443,348.566 C131.024,339.587 180.199,341.321 211.275,359.768 C214.997,361.978 216.218,366.785 214.012,370.501 C211.804,374.223 206.993,375.45 203.28,373.24 Z M202.396,396.984 C200.502,400.057 196.484,401.021 193.415,399.134 C170.91,385.301 136.593,381.293 109.968,389.375 C106.515,390.418 102.869,388.472 101.82,385.025 C100.78,381.573 102.727,377.933 106.174,376.883 C136.589,367.653 174.4,372.123 200.248,388.008 C203.317,389.898 204.283,393.918 202.396,396.984 Z M192.149,419.787 C190.644,422.255 187.431,423.028 184.972,421.524 C165.307,409.505 140.555,406.791 111.405,413.449 C108.596,414.093 105.796,412.333 105.155,409.524 C104.512,406.716 106.266,403.916 109.081,403.275 C140.981,395.983 168.344,399.121 190.417,412.609 C192.878,414.112 193.653,417.327 192.149,419.787 Z M153.743,299 C107.494,299 70,336.493 70,382.742 C70,428.996 107.494,466.486 153.743,466.486 C199.994,466.486 237.486,428.996 237.486,382.742 C237.486,336.493 199.994,299 153.743,299 L153.743,299 Z" />
      </g>
    </g>
  </svg>
)

export default Spotify
