import React from "react"
import { Link } from "gatsby"
import "./footer.scss"
import ApplePodcast from "././svgs/apple-podcasts"
import Spotify from "././svgs/spotify"
import Instagram from "././svgs/instagram"
import Twitter from "././svgs/twitter"

const Footer = props => (
  <footer>
    <div className="container">
      <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-site-links">
            <Link to="/" className="logo">
              Touré
            </Link>
            <nav>
              <ul className="footer-nav">
                <li>
                  Author
                  {/* <Link to="/">Podcast</Link> */}
                </li>
                <li>
                  Podcaster
                  {/* <Link to="/">Writing</Link> */}
                </li>
                <li>
                  TV Host
                  {/* <Link to="/contact-us">Contact</Link> */}
                </li>
              </ul>
            </nav>
          </div>
          <ul className="footer-social-links">
            <li>
              <a
                href="https://twitter.com/Toure"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className="icon" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/toureshow/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram className="icon" />
              </a>
            </li>
            <li>
              <a
                href="https://podcasts.apple.com/us/podcast/toure-show/id1313077481"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ApplePodcast className="icon" />
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/show/1YyDYzucMnmi51sscCRjoV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Spotify className="icon" />
              </a>
            </li>
          </ul>
        </div>
        <span className="copyright">
          © Copyright {new Date().getFullYear()}
        </span>
      </div>
    </div>
  </footer>
)

export default Footer
